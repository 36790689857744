import { useEffect, useState } from "react";
import { LazyBoundary } from "react-imported-component";
import { Switch, Route, Redirect } from "react-router-dom";
import Newsletter from "../partials/forms/Newsletter";
import Instagram from "../partials/Instagram";
import useAppState from "../providers/AppProvider";
import ErrorBoundary from "../utilities/ErrorBoundary";
import { AnimatePresence, motion } from "../utilities/FramerMotion";
import viewsPaths from "../utilities/viewPaths";
import { TransitionGroup, CSSTransition } from "react-transition-group";

Routes.propTypes = {};

export default function Routes() {
  const {
    content,
    location,
    setLocations,
    setCategories,
    byTemplate,
    stickyNotification,
    exiting,
  } = useAppState();

  const [routes, setRoutes] = useState([]);
  const isHome = window.location.pathname === "/";
  const hasStickyNotification =
    stickyNotification && !isHome ? "sticky_notification" : "";

  useEffect(() => {
    const map_locations = byTemplate("location");
    const map_categories = byTemplate("neighborhood");
    const baseRoutes = createBaseRoutes();
    setCategories(map_categories);
    setLocations(map_locations);
    setRoutes(baseRoutes);
  }, []);

  const createBaseRoutes = () => {
    const donotroute = ["footer", "header"];
    const setPage = (page) => page;

    return Object.keys(content)
      .map((key) => {
        const page = content[key];
        const template = page.template.toLowerCase();
        const Component = viewsPaths[`${template}`] || viewsPaths["default"];
        const formattedPage = setPage(page);

        return (
          !donotroute.includes(page.template) && (
            <Route
              exact
              key={formattedPage.id}
              path={formattedPage.path}
              render={(props) => (
                <LazyBoundary fallback={<div />}>
                  <ErrorBoundary>
                    <Component page={formattedPage} {...props} />
                  </ErrorBoundary>
                </LazyBoundary>
              )}
            />
          )
        );
      })
      .concat(
        <Route
          path="/"
          key="redirectAllElse"
          render={() => <Redirect to="/" />}
        />
      );
  };

  return (
    <main className={[hasStickyNotification, isHome ? " home" : null]}>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          timeout={1200}
          classNames="page-transition"
        >
          <div className={`switch-container ${exiting ? "exiting" : ""}`}>
            <Switch location={location}>{routes}</Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
      <AnimatePresence>
        {location.pathname === "/" && (
          <motion.div
            className="ig_wrap"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 1, delay: 0 }}
          >
            <div className="ig_content_wrap">
              <Newsletter />
              <Instagram />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </main>
  );
}
