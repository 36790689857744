export const getParametersFromUrl = (param) => {
  const search = window.location.search;

  const params = new URLSearchParams(search);
  let result = "";

  if (search.includes(param)) {
    result = params.get(param);

    return result;
  } else if (Object.keys(params).length === 0) {
    result = "";
    return result;
  }
};

export const SojernTracking_HomePage = () => {
  // *** From the doc: Necessary parameters: None ***
  /* Please fill the following values. */
  var params = {
    hpr: "NOPSI Hotel",
    hpid: "75045",
    sha256_eml: "",
    sha1_eml: "",
    md5_eml: "",
    ccid: "",
    pt: "HOME_PAGE",
  };

  /* Please do not modify the below code. */
  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var defaultParams = { vid: "hot" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  var pl = document.createElement("iframe");
  pl.type = "text/html";
  pl.setAttribute("style", "height:0; width: 0; display:none;");
  pl.async = true;
  pl.src =
    "https://static.sojern.com/cip/c/11.html?f_v=cp_v3_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);
};

export const SojernTracking_Product = () => {
  // *** From the doc: Necessary parameters: None ***
  /* Please fill the following values. */
  var params = {
    hpr: "NOPSI Hotel" /* Hotel Property */,
    hpid: "75045" /* Property ID */,
    sha256_eml: "" /* Hashed Email SHA256 */,
    sha1_eml: "" /* Hashed Email SHA1 */,
    md5_eml: "" /* Hashed Email MD5 */,
    ccid: "" /* Client Cookie id */,
    pt: "PRODUCT" /*Page Type-CONVERSION or HOME_PAGE or PRODUCT or SEARCH or SHOPPING_CART or TRACKING */,
  };

  /* Please do not modify the below code. */
  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var defaultParams = { vid: "hot", et: "hpr" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  var pl = document.createElement("iframe");
  pl.type = "text/html";
  pl.setAttribute("style", "height:0; width: 0; display:none;");
  pl.async = true;
  pl.src =
    "https://static.sojern.com/cip/c/11.html?f_v=cp_v3_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);
};

export const SojernTracking_Tracking = () => {
  /* Please fill the following values. */

  var params = {
    hpr: "NOPSI Hotel" /* Hotel Property */,
    hpid: "75045" /* Property ID */,
    pc: "" /* Page Category */,
    sha256_eml: "" /* Hashed Email SHA256 */,
    sha1_eml: "" /* Hashed Email SHA1 */,
    md5_eml: "" /* Hashed Email MD5 */,
    ccid: "" /* Client Cookie id */,
    pt: "TRACKING" /*Page Type-CONVERSION or HOME_PAGE or PRODUCT or SEARCH or SHOPPING_CART or TRACKING */,
  };
  /* Please do not modify the below code. */

  var cid = [];
  var paramsArr = [];
  var cidParams = [];
  var defaultParams = { vid: "hot" };
  for (const key in defaultParams) {
    params[key] = defaultParams[key];
  }
  for (const key in cidParams) {
    cid.push(params[cidParams[key]]);
  }
  params.cid = cid.join("|");
  for (const key in params) {
    paramsArr.push(key + "=" + encodeURIComponent(params[key]));
  }
  var pl = document.createElement("iframe");
  pl.type = "text/html";
  pl.setAttribute("style", "height:0; width: 0; display:none;");
  pl.async = true;
  pl.src =
    "https://static.sojern.com/cip/c/11.html?f_v=cp_v3_js&p_v=1&" +
    paramsArr.join("&");
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(pl);
};
