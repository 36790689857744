import React from "react";
import { Link } from "../widgets";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/cta.scss";
const cl = _classes(styles);
CTA.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(["solid", "outline"]),
  color: PropTypes.oneOf(["gold", "white", "blue"]),
  textColor: PropTypes.oneOf(["white", "blue", "gold"]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  mailto: PropTypes.string,
};
CTA.defaultProps = {
  color: "blue",
  type: "solid",
  className: "",
};
export default function CTA({
  text,
  link,
  type,
  color,
  textColor,
  className,
  onClick,
  target,
  mailto,
}) {
  const handleCTAClick = () => {
    if (mailto) {
      window.open(`mailto:${mailto}`);
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className={cl(["_", className ? className : ""])}>
      <Link
        onClick={handleCTAClick}
        className={cl(["link", color, type, `text-${textColor}`])}
        path={link}
        target={target}
      >
        {text}
      </Link>
    </div>
  );
}
