import React from "react";
import VideoPlayer from "../widgets/VideoPlayer";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/hero-video.scss";
import Reveal from "../widgets/Reveal";
import Logo from "./Logo";
const cl = _classes(styles);
HeroVideo.propTypes = {
  src: PropTypes.string,
  image: PropTypes.string,
  showlogo: PropTypes.bool,
  videoprops: PropTypes.object,
  className: PropTypes.string,
  video: PropTypes.string,
};
export default function HeroVideo({
  image,
  className,
  src,
  showlogo,
  videoprops,
}) {
  return (
    <div className={cl("_")}>
      <div
        className={
          className ? `${cl("container")} ${className}` : cl("container")
        }
      >
        <Reveal delay={0.2} preset={"fade"} className={cl("inner")}>
          {showlogo && (
            <div className={cl("logo")}>
              <Logo />
            </div>
          )}
          <VideoPlayer
            controls={false}
            autoplay={true}
            loop
            src={src}
            backgroundImage={image}
            muted
            containerStyles={{ paddingTop: 0 }}
          />
        </Reveal>
      </div>
    </div>
  );
}
