import { useEffect, useState } from "react";
import Nav from "./Nav";
import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/header.scss";
import useAppState from "../providers/AppProvider";
import HeroVideo from "./HeroVideo";
import Logo from "./Logo";
import { useViewportContext } from "../providers";
import { motion, useAnimation } from "../utilities/FramerMotion";
import { Waypoint } from "react-waypoint";
import Hamburger from "./Hamburger";
import CTA from "./CTA";
import MobileNav from "./MobileNav";
import NotificationBanner from "./NotificationBanner";
const cl = _classes(styles);

Header.propTypes = {};
function Header() {
  const {
    byTemplate,
    opennav,
    setOpenNav,
    allowStickyHeader,
    stickyNotification,
  } = useAppState();
  const { viewport } = useViewportContext();
  const [stickyHeader, setStickyHeader] = useState(false);
  const mobile = viewport.is("tablet");
  const home = byTemplate("home")[0];
  const footer = byTemplate("footer")[0];
  const banner = byTemplate("header")[0];
  const _window_path = window.location.pathname;
  const ishome = _window_path === "/";

  const headerContent = () => {
    return (
      <div className={cl([ishome && "home"])}>
        <div className={cl(["wrapper", mobile && "mobile"])}>
          <div
            className={cl([
              "container",
              mobile && "mobile_header",
              ishome && "home",
            ])}
          >
            {ishome ? (
              <div
                className={cl([
                  "logo",
                  ishome && !stickyHeader && !mobile && "hide_logo",
                ])}
              >
                <Logo short />
              </div>
            ) : (
              <div className={cl(["logo", ishome && "hide_logo"])}>
                <Logo short />
              </div>
            )}
            {mobile && (
              <CTA
                text="BOOK"
                link={footer.h4}
                className={cl("mobile_cta")}
                color="gold"
              />
            )}
            {mobile && (
              <Hamburger open={opennav} onClick={() => setOpenNav(!opennav)} />
            )}
            {mobile ? (
              <MobileNav open={opennav} />
            ) : (
              <Nav stickyHeader={stickyHeader} />
            )}
          </div>
          {stickyNotification && (
            <NotificationBanner
              className={cl("banner")}
              cta={banner.buttonlink4}
              content={banner.blurb1}
            />
          )}
        </div>
      </div>
    );
  };
  const controls = useAnimation();
  const onEnter = (s) => {
    mobile ? controls.start("mobile") : controls.start("static");
    if (s.previousPosition === "above") {
      setStickyHeader(false);
    }
  };

  const onLeave = (s) => {
    mobile ? controls.start("mobile") : controls.start("fixed");
    if (s.currentPosition === "above") {
      setStickyHeader(true);
    }
  };

  useEffect(() => {}, []);
  return (
    <motion.header
      className={cl([
        "_",
        ishome && "home_header",
        stickyHeader && "header_sticky",
      ])}
    >
      {mobile && headerContent()}
      {ishome && (
        <HeroVideo
          image={""}
          src={home.video_link}
          showlogo={mobile ? false : true}
        />
      )}
      <Waypoint onEnter={onEnter} topOffset="0px" onLeave={onLeave} />

      {!mobile && headerContent()}
    </motion.header>
  );
}

export default Header;
