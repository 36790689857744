import { _classes } from "../utilities/helpers";
import styles from "../../styles/partials/close-btn.scss";
const cl = _classes(styles);

CloseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  adaText: PropTypes.string,
};
export default function CloseButton({ onClick, className, adaText }) {
  return (
    <button
      onClick={() => onClick()}
      type="button"
      className={`${cl("_")} ${className && className} ${cl("close-btn")}`}
    >
      <span className={cl("icon-cross")}></span>
      <span className={cl("visually-hidden")}>{adaText || "close"}</span>
    </button>
  );
}
