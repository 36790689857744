import moment from "moment";

export const parse = (json, fallback = false) => {
  try {
    if (json === null || json === "") {
      return fallback;
    }

    return JSON.parse(json) || fallback;
  } catch (e) {
    console.error(e);
    return fallback;
  }
};
export const gaTrackEvent = ({
  label,
  category,
  action,
  googleConversionLabel,
  googleConversionValue,
}) => {
  if (window.ga && label && category && action) {
    ga("send", {
      hitType: "Event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: 0,
      google_conversion_id: 454521260,
      google_conversion_label: googleConversionLabel,
      google_conversion_value: googleConversionValue,
    });
  }
};
// Used to format event date/time
export const formatDate = (date) => moment(date).format("MM/DD/YYYY");
export const formatTime = (date) => {
  let time = moment(date).format("hh:mm");
  let formattedTime = "";
  time = time.replace(":", "");
  time === "1159"
    ? (formattedTime = "All Day")
    : (formattedTime = moment(date).format("LT"));
  return formattedTime;
};
// Creates a range (array) of numbers.
export const range = (integer, start = 0) =>
  [...Array(parseInt(integer)).keys()].map((i) => i + parseInt(start));

// Capitalize a string.
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// abbreviate class name with a prefix
export const _classes = (styles) => (name) => {
  if (typeof name === "string") {
    return styles[name] || name || "";
  }

  if (Array.isArray(name)) {
    return name.map((n) => styles[n] || n || "").join(" ");
  }

  return "";
};

// Limit how often a function fires.
// Great for event listeners like onResize
// delay in ms
export const debounce = (delay, fn) => {
  let time = Date.now();

  return function debounced(e) {
    if (time + delay - Date.now() < 0) {
      fn(e);
      time = Date.now();
    }
  };
};
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
//sleep
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

//form select options helper
export const selectOptionHelper = (arr) => {
  return arr.map((ele) => {
    return { value: ele, label: ele };
  });
};

//array chunks

export const _chunks = (array, size = 2) => {
  let arrayChunks = [];
  for (let i = 0; i < array.length; i += size) {
    let arrayChunk = array.slice(i, i + size);
    arrayChunks.push(arrayChunk);
  }
  return arrayChunks;
};
export const reservationTimes = [
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
].map((val) => ({ label: val, value: val }));
