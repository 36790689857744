import { _classes, parse } from "../utilities/helpers";
import styles from "../../styles/partials/footer.scss";
import { useAppState } from "../providers";
import { Image, Link } from "../widgets";
const cl = _classes(styles);
Footer.propTypes = {};
function Footer() {
  const { byTemplate } = useAppState();
  const page = byTemplate("footer")[0];
  const logos = page.fieldgroup2 || [];
  const socials = page.fieldgroup1 || [];
  const navitems = page.fieldgroup3 || [];
  const _topContent = () => {
    const cta1 = parse(page.buttonlink1);
    const cta2 = parse(page.buttonlink2);
    const cta3 = parse(page.buttonlink3);

    return (
      <>
        <div className={cl(["col_top", "contact_col"])}>
          {cta1 && cta1.link && (
            <div className={cl("contact_item")}>
              RESERVATIONS:{" "}
              <Link className={cl("contact_link")} path={cta1.link}>
                {cta1.text}
              </Link>{" "}
            </div>
          )}
          {cta2 && cta2.link && (
            <div className={cl("contact_item")}>
              GENERAL INQUIRIES:{" "}
              <Link className={cl("contact_link")} path={cta2.link}>
                {cta2.text}
              </Link>{" "}
            </div>
          )}
          {cta3 && cta3.link && (
            <div className={cl("contact_item")}>
              <Link className={cl("contact_link")} path={cta3.link}>
                {cta3.text}
              </Link>{" "}
            </div>
          )}
        </div>
        <div className={cl(["col_top", "address_col"])}>
          <Link path={page.h3} className={cl("gmap_link")}>
            <address>
              {page.address1}
              <br />
              {page.address2}
            </address>
          </Link>
        </div>
        <div className={cl(["col_top", "social_col"])}>
          <h2 className={cl("signup")}>Follow Us On</h2>
          <div className={cl("icons")}>
            {socials &&
              socials.length &&
              socials.map((icon) => (
                <Link key={icon.id} path={icon.h2} className={cl("icon")}>
                  <img
                    draggable={false}
                    data-src={icon.image1}
                    alt={icon.h1}
                    className={cl(["lazyload", "icon_image"])}
                  />
                </Link>
              ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <footer className={cl("_")}>
      <div className={cl("container")}>
        <div className={cl(["row", "row_flex"])}>{_topContent()}</div>
        {logos && logos.length > 0 && (
          <div className={cl("row")}>
            <div className={cl("logos")}>
              {logos.map((logo) => (
                <Link key={logo.id} path={logo.h2} className={cl("logo")}>
                  <Image
                    type="element"
                    src={logo.image1}
                    alt={logo.image1_alt_text || logo.h1}
                  />
                </Link>
              ))}
            </div>
          </div>
        )}
        {navitems && navitems.length > 0 && (
          <div className={cl(["row", "last"])}>
            <div className={cl("nav")}>
              {navitems.map((item) => (
                <Link className={cl("nav_item")} key={item.id} path={item.h2}>
                  {item.h1}
                </Link>
              ))}

              <div className={cl("nav_item")}>
                <a
                  className={cl("footer__credits")}
                  href="https://www.onceuponatime.agency/hospitality"
                  data-txt="Credits"
                  target="_blank"
                >
                  <span>Once Upon a Time</span>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
}

export default Footer;
