import { _classes, parse } from "../utilities/helpers";
import { useAppState } from "../providers";
import styles from "../../styles/partials/notification_banner.scss";
import CTA from "./CTA";
import CloseButton from "./CloseButton";
const cl = _classes(styles);

NotificationBanner.propTypes = {
  content: PropTypes.string,
  cta: PropTypes.string,
  position: PropTypes.string,
};
NotificationBanner.defaultProps = {
  position: "fixed",
};
export default function NotificationBanner({ content, cta, position }) {
  const {
    stickyNotification,
    setStickyNotification,
    allowStickyHeader,
    setAllowStickyHeader,
  } = useAppState();
  const isHome = window.location.pathname === "/";
  const notificationBannerVisible =
    stickyNotification && isHome ? "home-page" : "";
  const _cta = cta && cta !== "" ? parse(cta) : null;

  return (
    <div className={cl(["_", notificationBannerVisible])}>
      <div className={cl("banner_wrapper")}>
        <div className={cl("banner_flex")}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {_cta.link && _cta.text && (
            <CTA
              className={cl("cta")}
              color={"gold"}
              text={_cta.text}
              link={_cta.link}
            />
          )}
          <CloseButton
            onClick={() =>
              setStickyNotification(false) && setAllowStickyHeader(false)
            }
            className={cl("close_button")}
          />
        </div>
      </div>
    </div>
  );
}
