import browser from "bowser";
import InvalidBrowser from "./utilities/InvalidBrowser/InvalidBrowser.js";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import CookiePopup from "./partials/CookiePopup.js";
import APP_CONFIG from "./config/appConfig";
import Routes from "./containers/Routes.js";
import { useEffect } from "react";
import useAppState from "./providers/AppProvider.js";
import {
  SojernTracking_HomePage,
  SojernTracking_Product,
  SojernTracking_Tracking,
} from "./utilities/SojernTracking";
import { trackingDisabled } from "./config/appConfig";

App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default function App({ location }) {
  const { byPath } = useAppState();

  useEffect(() => {
    document.addEventListener("lazybeforeunveil", function (e) {
      var bg = e.target.getAttribute("data-bg");
      if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")";
      }
    });
    pageChangeRoutine();
    setTimeout(hideLoader, APP_CONFIG.loadingDuration);
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
    pageChangeRoutine();
  }, [location.pathname, location.search]);

  const hideLoader = () =>
    (document.querySelector(".site-loader").className +=
      " site-loader--loaded");

  const isValidBrowser = () =>
    !(browser.msie && parseInt(browser.version) < 11);

  const pageChangeRoutine = () => {
    const page = byPath(location.pathname);
    document.title = page.pagetitle || byPath("/").pagetitle;

    setTimeout(() => window.scrollTo(0, 0), 100);
  };

  const trackPageView = (page) => {
    var urls = window.location.href;
    const { title } = document;
    if (window.ga) {
      ga("send", "pageview", { page, title });
    }
    if (trackingDisabled) {
      return;
    } else {
      if (page === "/") {
        SojernTracking_HomePage();
      } else if (/rooms-suites/.test(urls)) {
        SojernTracking_Product();
      } else if (
        /our-hotel/.test(urls) ||
        /offers/.test(urls) ||
        /dining/.test(urls) ||
        /plan-an-event/.test(urls) ||
        /happenings/.test(urls) ||
        /gallery/.test(urls)
      ) {
        SojernTracking_Tracking();
      }
    }
  };

  if (!isValidBrowser()) {
    return <InvalidBrowser />;
  }

  return (
    <div className={`app`}>
      <Header />
      <Routes />
      <Footer />
      <CookiePopup />
    </div>
  );
}
