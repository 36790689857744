import { createContext, useContext, useState, useEffect } from "react";
import moment from "moment";

const AppContext = createContext({});

AppProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export function AppProvider({ children, content, history, location }) {
  const [accessible, setAccessible] = useState(false);
  const [modal, setModal] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [opennav, setOpenNav] = useState(false);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [pageTransition, setPageTransition] = useState(false);
  const [activeLocation, setActiveLocation] = useState(null);
  const allowedPages = ["our-hotel", "plan-an-event", "dining"];
  const [stickyNotification, setStickyNotification] = useState(false);
  const [allowStickyHeader, setAllowStickyHeader] = useState(true);
  const [exiting, setExiting] = useState(false);
  const handleExit = () => {
    setExiting((state) => !state);
  };

  const _sortEvents = (a, b) =>
    moment(a.start_date).toDate() - moment(b.start_date).toDate();

  const byId = (id) => content[id] || {};

  const byPath = (path) => {
    path = path.toLowerCase();
    path = path.slice(-1) === "/" && path.length > 1 ? path.slice(0, -1) : path;

    const id = Object.keys(content).find((key) => content[key].path === path);
    return content[id] || {};
  };

  const byTemplate = (template) =>
    Object.keys(content)
      .filter((key) => content[key].template === template)
      .map((key) => content[key])
      .sort((a, b) => a.sortorder - b.sortorder);

  const childrenById = (id) =>
    Object.keys(content)
      .filter((key) => content[key].parentid === id)
      .map((key) => content[key])
      .sort((a, b) => a.sortorder - b.sortorder);

  const childrenByPath = (path) => childrenById(byPath(path).id);

  const parentById = (id) => byId(byId(id).parentid);

  const parentByPath = (path) => byId(byPath(path).parentid);

  // Check if modal and sticky header
  const headerContent = byTemplate("header")[0];
  console.log("headercontent", headerContent);

  useEffect(() => {
    //modal
    headerContent.checkbox2 === true ? setModal(true) : setModal(false);
    //sticky header
    headerContent.checkbox1 === true
      ? setStickyNotification(true)
      : setStickyNotification(false);
  }, []);

  const allEvents = () => {
    const calendar = byTemplate("calendar")[0];
    const events = childrenById(calendar.id);

    return events
      .reduce((sum, event) => {
        const eventType = JSON.parse(event.calendartype).type;

        if (eventType === "recurring" && event.parentid === event.calendarid) {
          const children = childrenById(event.id).sort(_sortEvents);

          const child =
            children.find(
              (e) => moment(e.start_date).toDate() >= moment().toDate()
            ) || event;

          if (moment(event.start_date).toDate() >= moment().toDate()) {
            //if original event is still in future, push OG event
            sum.push(event);
          } else {
            //otherwise push most next upcoming child
            sum.push({
              ...event,
              id: child.id,
              parentid: child.parentid,
              start_date: child.start_date,
              end_date: child.end_date,
            });
          }
        }

        if (eventType !== "recurring") {
          sum.push(event);
        }

        return sum;
      }, [])
      .sort(_sortEvents);
  };

  const activeEvents = () => {
    return allEvents()
      .filter((event) => {
        const today = new Date();
        const calendarType = JSON.parse(event.calendartype);

        return (
          moment(event.end_date).toDate().getTime() >= today.getTime() ||
          (calendarType.type === "consecutive" &&
            moment(event.end_date).toDate().getTime() >= today.getTime())
        );
      })
      .sort(_sortEvents);
  };

  const toggleAccessible = (e) => {
    e.preventDefault();
    setAccessible(!accessible);
    document.querySelector("html").classList.toggle("accessible");
  };
  const togglePageTransition = (path) => {
    if (!pageTransition) {
      setPageTransition(true);
      setTimeout(() => {
        history.push(path);
        setPageTransition(false);
      }, 1350);
    }
  };
  return (
    <AppContext.Provider
      value={{
        content,
        history,
        location,
        byId,
        byPath,
        byTemplate,
        childrenById,
        childrenByPath,
        parentById,
        parentByPath,
        allEvents,
        activeEvents,
        accessible,
        toggleAccessible,
        animating,
        setAnimating,
        opennav,
        setOpenNav,
        allowedPages,
        locations,
        setLocations,
        categories,
        setCategories,
        activeCategory,
        setActiveCategory,
        activeLocation,
        setActiveLocation,
        modal,
        setModal,
        stickyNotification,
        setStickyNotification,
        allowStickyHeader,
        setAllowStickyHeader,
        pageTransition,
        setPageTransition,
        togglePageTransition,
        exiting,
        handleExit,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default function useAppState() {
  return useContext(AppContext);
}
