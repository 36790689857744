/******************************************************************************\
 CONFIG: COMPONENT DECLARATION, PROPTYPES, DEFAULT PROPS, & COMPONENT STATE
\******************************************************************************/
const _num = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const num_str = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

const Image = (props) => {
  const { align, alt, className, src, type, imagerole, figureprops } = props;
  const isbg = type === "background";
  const imgbasestyles = isbg
    ? {
        position: "absolute",
        opacity: 0,
      }
    : {};
  return (
    <figure
      className={`lazyload image image--${type} image--aligned-${align} ${className}`}
      {...figureprops}
      data-bg={isbg ? src : ""}
      style={{
        position: "relative",
        margin: 0,
        ...figureprops.style,
      }}
    >
      <img
        data-src={src}
        draggable={false}
        alt={alt}
        style={imgbasestyles}
        role={imagerole}
        className="lazyload"
      />
      {props.caption && props.caption.trim() !== "" && (
        <figcaption>{props.caption}</figcaption>
      )}
    </figure>
  );
};
Image.propTypes = {
  align: PropTypes.oneOf([..._num, ...num_str]).isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  fixedAspectRatio: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["background", "element"]).isRequired,
  imagerole: PropTypes.string,
  figureprops: PropTypes.object,
  caption: PropTypes.string,
};
//default props
Image.defaultProps = {
  align: 5,
  className: "",
  fixedAspectRatio: true,
  type: "background",
  imagerole: "presentation",
  figureprops: {},
  caption: "",
};
export default Image;
