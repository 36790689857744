import { _classes } from "../utilities/helpers";
import { useAppState } from "../providers";
import styles from "../../styles/partials/sub-nav.scss";

import Link from "../widgets/Link";
import { Image } from "../widgets";
const cl = _classes(styles);

SubNav.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
};
export default function SubNav({ className, items }) {
  const filteredChildren = items && items.filter((child) => child.showinnav);
  return (
    <div className={`${className} ${cl("_")}`}>
      <div className={cl("_")}>
        <div className={cl("inner")}>
          {filteredChildren &&
            filteredChildren.map((child) => {
              const image =
                child.image5 && child.image5 !== "" ? child.image5 : "";
              return (
                <Link
                  key={child.id}
                  className={cl("subnav_item")}
                  path={child.path}
                >
                  <Image
                    src={image}
                    alt={
                      child.image5_alt_text && child.image5_alt_text !== ""
                        ? child.image5_alt_text
                        : child.h1
                    }
                  />
                  <span className={cl("link_title")}>{child.linktitle}</span>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}
