import React from "react";
import { Link, Image } from "../widgets";
import logo from "../../images/logo/logo-white.svg";
import short_logo from "../../images/logo/short-logo.svg";
Logo.propTypes = {
  short: PropTypes.bool,
};
export default function Logo({ short }) {
  const _path = window.location.pathname;
  return (
    <div>
      <Link path={_path === "/" ? "" : "/"}>
        <Image
          src={short ? short_logo : logo}
          alt={"Nopsi Hotel"}
          type="element"
        />
      </Link>
    </div>
  );
}
