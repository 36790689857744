import { Link as RRLink } from "react-router-dom";
import useAppState from "../providers/AppProvider";

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  path: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  target: PropTypes.string,
  transition: PropTypes.bool,
};

Link.defaultProps = {
  target: "_blank",
  path: "",
  search: "",
  hash: "",
  transition: false,
};

function Link({
  children,
  id,
  path,
  search,
  hash,
  title,
  className,
  onClick,
  ariaLabel,
  target,
}) {
  const { byId, byPath, handleExit, history } = useAppState();

  const getContent = (id, path) => {
    let content = {};
    if (typeof id === "number") {
      content = byId(id);
    } else if (path) {
      content = byPath(path);
    }

    return content.id ? content : null;
  };

  const getDomain = (url) => url.replace(/https?:\/\//, "").split("/")[0];

  const isEmail = (url) => url.search(/mailto:/) !== -1;

  const isFile = (url) => url.search(/\.pdf/) !== -1;

  const isPhone = (url) => url.search(/tel:/) !== -1;

  const isExternal = (path) => {
    if (!path || isEmail(path) || isFile(path) || isPhone(path)) {
      return true;
    }
    const { href } = window.location;
    const sameDomain = getDomain(href) === getDomain(path);
    return path.search(/https?:\/\//) !== -1 && !sameDomain;
  };

  const content = getContent(id, path);
  const handleClick = (e) => {
    handleExit();
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();
    setTimeout(() => {
      history.push(path);
      window.scrollTo(0, 0);
      handleExit();
    }, 1000);
    onClick && onClick(e);
  };
  // should we even return anything here?
  if (!content && !path && !onClick) {
    return (
      <span aria-label={ariaLabel} className={className}>
        {title || children}
      </span>
    );
  }

  if (!content && !path && onClick) {
    return (
      <button aria-label={ariaLabel} className={className} onClick={onClick}>
        {title || children}
      </button>
    );
  }

  // handle routes with associated content or param routes created by the router
  if (content || !isExternal(path)) {
    return (
      <RRLink
        aria-label={ariaLabel}
        className={className}
        onClick={handleClick}
        to={{
          pathname: path,
          search,
          hash,
        }}
      >
        {title || children || path}
      </RRLink>
    );
  }

  // external links,  mailto, tel, etc
  return (
    <a
      className={className}
      onClick={onClick}
      aria-label={ariaLabel}
      href={`${path}${hash}${search}`}
      target={target}
    >
      {title || children || path}
    </a>
  );
}

export default Link;
