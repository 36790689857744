import { useEffect, useRef } from "react";
import Slick from "react-slick";
import Image from "widgets/Image";
// when using the carousel in your component:
// wrap the carousel in a container
// it will inherit the size of it's wrapper :)

Carousel.propTypes = {
  slides: PropTypes.array,
  settings: PropTypes.object,
  className: PropTypes.string,
  keyboardControl: PropTypes.bool
};
Carousel.defaultProps = {
  settings: {
    arrows: true,
    dots: true
  }
};

function Carousel(props){
  const {slides, className, settings, keyboardControl } = props;

  const slick = useRef();
  
  useEffect(()=>{
    window.addEventListener("keydown", handleKeyDown);
    return ()=>{
      if (typeof window !== "undefined") {
        window.removeEventListener("keydown", handleKeyDown);
      }
    }
  }, []);

  const imageComponentFor = (slide) => {
    const imgProps = {
      align: slide.image1_anchor,
      src: slide.image1,
      alt: slide.image1_alt_text
    };
    return <Image {...imgProps} />;
  }

  const handleKeyDown = e => {
    if (keyboardControl && slick) {
      if (
        !document.activeElement ||
        document.activeElement.tagName !== "INPUT"
      ) {
        if (e.key === "ArrowLeft" || e.key === "Left") {
          slick.slickPrev();
        } else if (e.key === "ArrowRight" || e.key === "Right") {
          slick.slickNext();
        }
      }
    }
  };

  if (slides.length === 1) {
    return (
      <div
        className={`carousel-with-one-image ${className}`}
        style={{ padding: 0 }}
      >
        {imageComponentFor(slides[0])}
      </div>
    );
  }

  return (
    <div className={`carousel ${className}`}>
      <Slick ref={slick} {...settings}>
        {slides.map((slide, i) => {
          return (
            <div key={i}>
              {imageComponentFor(slide)}
            </div>
          );
        })}
      </Slick>
    </div>
  );
}

export default Carousel;
