import Link from "../widgets/Link";
import { useState } from "react";
import AnimateHeight from "react-animate-height";
import useAppState from "../providers/AppProvider";
import { _classes } from "../utilities/helpers";
import { motion } from "../utilities/FramerMotion";
import styles from "../../styles/partials/mobile-nav.scss";
import Image from "../widgets/Image";
const cl = _classes(styles);
const variantsnav = {
  open: { opacity: 1, pointerEvent: "all" },
  closed: { opacity: 1, transition: { delay: 0.4 }, pointerEvent: "none" },
};
const variantsul = {
  open: {
    transition: { staggerChildren: 0.05, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.03, staggerDirection: -1 },
  },
};
const variants_li = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: 30,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
};
MobileNav.propTypes = {
  open: PropTypes.bool,
};

function MobileNav({ open }) {
  const { childrenById, byTemplate, setOpenNav } = useAppState();
  const [activeSubNav, setActiveSubNav] = useState(null);

  const items = childrenById(1).filter((item) => item.showinnav === true);
  const socialIcons = byTemplate("footer")[0].fieldgroup1;
  const getChildren = (id) => {
    const children = childrenById(id);
    return children.filter((child) => child.showinnav === true);
  };

  return (
    <motion.nav
      animate={open ? "open" : "closed"}
      variants={variantsnav}
      className={cl(["navigation", open && "open_nav"])}
    >
      <motion.ul variants={variantsul} className={cl("nav__items")}>
        {items &&
          items.map((item) => {
            const path = item.path;

            return (
              <motion.li
                variants={variants_li}
                key={item.id}
                className={cl("nav__item")}
              >
                <Link
                  className={cl([
                    "nav__link",
                    item.checkbox4 === true && "has_sub_nav",
                    activeSubNav === item.id && "active",
                  ])}
                  path={item.checkbox4 ? null : path}
                  onClick={() =>
                    item.checkbox4
                      ? setActiveSubNav(item.id)
                      : setOpenNav(false)
                  }
                >
                  {item.linktitle}
                  {item.checkbox4 === true && (
                    <span className={cl("arrow")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 8.87 4.84"
                      >
                        <path d="M8.75.12a.4.4 0 0 0-.57 0L4.43 3.86.69.12a.403.403 0 1 0-.57.57l4 4a.36.36 0 0 0 .28.12.4.4 0 0 0 .29-.12l4-4a.41.41 0 0 0 .06-.57Z" />
                      </svg>
                    </span>
                  )}
                </Link>

                {item.checkbox4 === true && (
                  <AnimateHeight height={activeSubNav == item.id ? "auto" : 0}>
                    <ul className={cl("sub_nav")}>
                      {getChildren(item.id).map((child) => {
                        const path = child.path;
                        return (
                          <li key={child.id} className={cl("sub_nav_item")}>
                            <Link
                              className={cl("sub_nav_link")}
                              path={path}
                              onClick={() => setOpenNav(false)}
                            >
                              {child.h1}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </AnimateHeight>
                )}
              </motion.li>
            );
          })}
        <motion.li>
          {socialIcons && socialIcons.length > 0 && (
            <div className={cl("social_icons")}>
              {socialIcons.map((icon) => (
                <Link key={icon.id} path={icon.h2} className={cl("icon")}>
                  <Image
                    src={icon.image1}
                    alt={icon.image1_alt_text || icon.h1}
                  />
                </Link>
              ))}
            </div>
          )}
        </motion.li>
      </motion.ul>
    </motion.nav>
  );
}

export default MobileNav;
