import { Waypoint } from "react-waypoint";
import { useAnimation, motion } from "../utilities/FramerMotion";
import { useEffect } from "react";

Reveal.propTypes = {
  variants: PropTypes.shape({
    hidden: PropTypes.object,
    visible: PropTypes.object,
  }),
  reveal: PropTypes.bool,
  preset: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
  element: PropTypes.string,
  transitionPreset: PropTypes.object,
  bottomOffset: PropTypes.string,
};

Reveal.defaultProps = {
  variants: {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  },
  className: "",
  delay: 0,
  duration: 940,
  element: "div",
  transitionPreset: { ease: [0, 0, 0.2, 1], duration: 1.2 },
  bottomOffset: "25px",
};

const PRESETS = {
  fade: {
    hidden: { opacity: "0%" },
    visible: { opacity: "100%" },
  },
  fadeUp: {
    hidden: { opacity: 0, y: "50%" },
    visible: { opacity: 1, y: "0%" },
  },
  fadeDown: {
    hidden: { opacity: 0, y: "-50%" },
    visible: { opacity: 1, y: "0%" },
  },
  slideUp: {
    hidden: { opacity: 1, y: 50 },
    visible: { opacity: 1, y: 0 },
  },
  fadeLeft: {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0 },
  },
  fadeRight: {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  },
  grow: {
    hidden: { width: "0%" },
    visible: { width: "calc(100% + 16px)" },
  },
};

export default function Reveal({
  variants,
  reveal,
  preset,
  className,
  children,
  delay,
  duration,
  element,
  bottomOffset,
  transitionPreset,
}) {
  const controls = useAnimation();

  const onEnter = (res) => {
    // console.log("ENTER INFO ", res);
    if (res.currentPosition === "inside" || res.previousPosition === "below") {
      controls.start("visible");
    } else {
      controls.start("visible");
    }
  };

  const getPreset = (name, { delay, duration }) => {
    const preset = PRESETS[name];
    if (preset) {
      return Object.keys(preset).reduce((sum, key) => {
        sum[key] = { ...preset[key] };
        sum[key].transition = {
          delay: delay / 1000,
          duration: duration / 1000,
        };
        return sum;
      }, {});
    }
  };

  const setVariants = () => getPreset(preset, { delay, duration }) || variants;

  useEffect(() => {
    if (reveal) {
      onEnter();
    }
  }, []);

  const Component = motion[element];

  return (
    <Component
      className={className}
      initial="hidden"
      animate={controls}
      variants={setVariants()}
      transition={transitionPreset}
    >
      <Waypoint
        onEnter={onEnter}
        fireOnRapidScroll={false}
        bottomOffset={bottomOffset}
      />
      {children}
    </Component>
  );
}
