export const calendarArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
  </svg>
);

export const thinCalendarArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.75 12.01">
    <polyline points="0.35 0.35 6.04 6.04 0.42 11.65" fill="none" />
  </svg>
);
