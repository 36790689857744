import styles from "../../styles/partials/cookie-popup.scss";
import { Link } from "../widgets";
import CTA from "../partials/CTA";
import { _classes } from "../utilities/helpers";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "../utilities/FramerMotion";

const cl = _classes(styles, "cookie_popup");

CookiePopup.propTypes = {};
export default function CookiePopup() {
  const [cookiesAllowed, setCookiesAllowed] = useState(false);

  useEffect(() => {
    const sessionCookie = localStorage.getItem("cookiesAccepted");
    const expirationDate = getLocalStorageCookieDate();
    const now = new Date();

    if (sessionCookie && expirationDate >= now) {
      saveCookiePreference();
    }
  }, []);

  const enableGoogleConsent = () => {
    if (window.gtag) {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
        analytics_storage: "granted",
      });
    }
  };
  const getLocalStorageCookieDate = () => {
    const expiration = localStorage.getItem("cookiesAcceptedExpiration");
    return new Date(expiration);
  };

  const saveCookiePreference = () => {
    enableGoogleConsent();
    setCookiesAllowed(true);
    setCookieAcceptedLocalStorage();
  };
  const setCookieAcceptedLocalStorage = () => {
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 30);
    localStorage.setItem("cookiesAccepted", true);
    localStorage.setItem("cookiesAcceptedExpiration", expiration.toISOString());
  };

  return (
    <AnimatePresence>
      {!cookiesAllowed && (
        <motion.div
          className={cl("cookie__popup")}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.75, delay: 0 }}
        >
          <div className={cl("container")}>
            <div className={cl("content__wrapper")}>
              <div className={cl("blurb")}>
                We'd like to use cookies to understand your use of our website
                and to give you a better experience. To find out more about
                cookies and how to change your choices, please to go our{" "}
                <Link path={"/privacy-policy"}>Privacy Policy</Link>.
              </div>
              <CTA
                color={"gold"}
                text={"Accept"}
                className={cl("accept__cookie")}
                onClick={saveCookiePreference}
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
