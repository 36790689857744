import React from "react";
import styles from "../../styles/partials/hamburger.scss";
import { _classes } from "../utilities/helpers";
const cl = _classes(styles);
Hamburger.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  ishome: PropTypes.bool,
};
export default function Hamburger({ open, onClick }) {
  return (
    <button onClick={onClick} className={cl(["hamburger"])}>
      <span className={cl(["line", open && "line_1"])} />
      <span className={cl(["line", open && "line_2"])} />
      <span className={cl(["line", open && "line_3"])} />
      <span className={cl(["line", open && "line_4"])} />
    </button>
  );
}
