import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import { Controller } from "react-hook-form/dist/index.ie11";
import ReactDayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import moment from "moment";
import { useFormContext } from "./FormProvider";
import { calendarArrow, thinCalendarArrow } from "../widgets/SVG";
import { v4 as uuidv4 } from "uuid";

const UUID = uuidv4();

DatePickerInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  dayPickerProps: PropTypes.object,
  fromMonth: PropTypes.object,
  format: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
};

DatePickerInput.defaultProps = {
  name: "date",
  format: "L",
  dayPickerProps: {},
  className: "",
};

export default function DatePickerInput({
  name,
  placeholder,
  label,
  rules,
  dayPickerProps,
  fromMonth,
  className,
  defaultValue,
  format,
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;

  const internalFormat = "MM/DD/YYYY";

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <div className={"field field-datepicker " + className}>
          <Label value={label} htmlFor={id} />
          <div className={"control"}>
            <ReactDayPickerInput
              className="date_picker"
              inputProps={{ id }}
              numberOfMonths={1}
              fromMonth={fromMonth}
              value={
                value ? moment(value, internalFormat).format(format) : undefined
              }
              readOnly={true}
              dayPickerProps={{
                navbarElement: NavbarElement,
                onDayClick: (date) =>
                  onChange(moment(date).format(internalFormat)),
                disabledDays: {
                  before: moment().toDate(),
                },
                highlighted: value,
                ...dayPickerProps,
              }}
              placeholder={placeholder}
              formatDate={formatDate}
              parseDate={parseDate}
              format={"L"}
            />
            <svg
              className={"dropdown-arrow"}
              height="20"
              width="20"
              viewBox="0 0 20 20"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
          <ErrorMessage error={errors[name]} />
        </div>
      )}
    />
  );
}

NavbarElement.propTypes = {
  month: PropTypes.string,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
};

function NavbarElement({ month, onNextClick, onPreviousClick }) {
  return (
    <div className={"DayPicker-Caption-Custom"}>
      <button
        type="button"
        onClick={() => onPreviousClick(null)}
        className={"DayPicker-Arrow previous"}
      >
        {thinCalendarArrow}
      </button>
      <span>{moment(month).format("MMMM")}</span>
      <button
        type="button"
        onClick={() => onNextClick(null)}
        className={"DayPicker-Arrow next"}
      >
        {thinCalendarArrow}
      </button>
    </div>
  );
}
