import Link from "../widgets/Link";
import { useState, useEffect } from "react";
import useAppState from "../providers/AppProvider";
import { _classes } from "../utilities/helpers";
import CTA from "./CTA";
import styles from "../../styles/partials/nav.scss";
import SubNav from "./SubNav";
import { AnimatePresence, motion } from "framer-motion";
const cl = _classes(styles);
Nav.propTypes = {
  stickyHeader: PropTypes.object,
};

function Nav({ stickyHeader }) {
  const [focusedItem, setFocusedItem] = useState(null);
  const { childrenById, byTemplate, allowedPages } = useAppState();
  const header = byTemplate("header")[0];
  const ishome = window.location.pathname === "/";
  const items = childrenById(1).filter((item) => item.showinnav === true);
  const handleEnter = (id) => {
    setFocusedItem(id);
  };
  const handleExit = () => {
    setFocusedItem(null);
  };
  return (
    <nav className={cl(["_", ishome && !stickyHeader && "sticky_nav"])}>
      <ul className={cl("items")}>
        {items &&
          items.map((item) => {
            const children = childrenById(item.id);
            const isAllowed =
              items && items.length > 0
                ? allowedPages.includes(item.slug)
                : null;
            const active =
              item.path === window.location.pathname ||
              window.location.pathname.includes(item.path);
            return (
              <li
                onMouseLeave={() => handleExit()}
                onMouseEnter={() => handleEnter(item.id)}
                key={item.id}
                className={cl(["item"])}
              >
                <Link
                  className={cl([
                    "link",
                    isAllowed && focusedItem === item.id && "has_arrow",
                    active && `${cl("link_active")}`,
                  ])}
                  path={item.path}
                >
                  {item.linktitle}
                  {isAllowed && (
                    <span
                      className={cl([
                        "arrow",
                        focusedItem === item.id && "active",
                      ])}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg">
                        <path
                          stroke="#FFF"
                          strokeWidth="2"
                          fill="none"
                          d="M11.263 2.501 6.286 7.478 1.309 2.501"
                        />
                      </svg>
                    </span>
                  )}
                </Link>
                {isAllowed && focusedItem === item.id && (
                  <AnimatePresence>
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <SubNav
                        className={cl("subnav_wrapper")}
                        items={children}
                      />
                    </motion.div>
                  </AnimatePresence>
                )}
              </li>
            );
          })}
        <li className={cl(["item", "book_cta"])}>
          <CTA
            className={cl("book_now")}
            text="BOOK NOW"
            link={header.h6}
            color="gold"
            textColor="white"
            type="outline"
          />
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
