// import axios from "axios";
// import Link from "../widgets/Link";
// import Image from "../widgets/Image";

import React from "react";
import { useAppState, useViewportContext } from "../providers";
import { Image, Link } from "../widgets";
import { _classes } from "../utilities/helpers";
import Slick from "react-slick";
import styles from "../../styles/partials/instagram.scss";
import iglogo from "../../images/icons/ig-white.svg";
const cl = _classes(styles);
export default function Instagram() {
  const { byTemplate } = useAppState();
  const { viewport } = useViewportContext();
  const mobile = viewport.is("mobile");
  const page = byTemplate("footer")[0];
  const posts = page.fieldgroup4 || [];
  let _max = mobile ? 4 : posts.length;
  const _renderPost = () => {
    return posts.slice(0, _max).map((item) => (
      <Link className={cl("slide")} path={item.h2} key={item.id}>
        <Image
          className={cl("image")}
          src={item.image1}
          alt={item.image1_alt_text}
        />
        <div
          className={cl("ig_logo")}
          style={{ backgroundImage: `url(${iglogo})` }}
        />
      </Link>
    ));
  };
  const settings = {
    dots: false,
    arrows: true,
    slidesToShow: 5,
    infinite: true,
  };
  if (posts && posts.length > 0) {
    return mobile ? (
      <div className={cl("_")}>
        <div className={cl("container")}>
          <div className={cl("mobile")}>{_renderPost()}</div>
        </div>
      </div>
    ) : (
      <div className={cl("_")}>
        <div className={cl("container")}>
          <Slick {...settings}>{_renderPost()}</Slick>
        </div>
      </div>
    );
  } else {
    return false;
  }
}
