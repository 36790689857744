// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._button__button___RNv-a{-webkit-transition:250ms all ease-in-out;transition:250ms all ease-in-out;background:transparent}._button__button___RNv-a:hover{opacity:.7}", "",{"version":3,"sources":["webpack://./src/styles/widgets/_button.scss"],"names":[],"mappings":"AAAA,yBACE,wCAAA,CAAA,gCAAA,CACA,sBAAA,CAEA,+BACE,UAAA","sourcesContent":[".button {\n  transition: 250ms all ease-in-out;\n  background: transparent;\n\n  &:hover {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "_button__button___RNv-a"
};
export default ___CSS_LOADER_EXPORT___;
