import React from "react";
import { FormProvider, StandardInput, SubmitInput } from "../../form";
import styles from "../../../styles/partials/newsletter.scss";
import { _classes, gaTrackEvent } from "../../utilities/helpers";
import { useAppState } from "../../providers";
import Reveal from "../../widgets/Reveal";
const cl = _classes(styles);
Newsletter.propTypes = {
  page: PropTypes.object,
};

export default function Newsletter() {
  const { byTemplate } = useAppState();
  const page = byTemplate("footer")[0];
  return (
    <div className={cl(["_"])}>
      <div className={cl("content")}>
        <div className={cl("header")}>
          <Reveal element="h2" className={cl("title")}>
            {page.h6}
          </Reveal>
          <Reveal className={cl("tagline")}>
            <div dangerouslySetInnerHTML={{ __html: page.h5 }} />
          </Reveal>
        </div>
        <FormProvider
          onSubmit="newsletter"
          onSuccess={() => {
            gaTrackEvent({
              category: "Form",
              action: "Completion",
              label: "Newsletter",
            });
          }}
        >
          <Reveal className={cl("field_row")}>
            <StandardInput
              name="email"
              placeholder="Email Address"
              rules={{ required: "Please enter a valid email." }}
              className={"styles.newsletter__input"}
            />
            <div className={cl("field_submit")}>
              <SubmitInput text="Submit" />
            </div>
          </Reveal>
        </FormProvider>
      </div>
    </div>
  );
}
